/* Styles de base pour les tables */
.organ-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  table-layout: fixed;
}

.organ-table th,
.organ-table td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word;
  white-space: normal;
}

.organ-table th {
  background-color: #640097;
  color: white;
}

.organ-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.organ-table .button-container {
  display: flex;
  justify-content: center;
  gap: 5px;
}


.form-box {
  background-color: #ccc; /* Couleur violet très clair */
  padding: 20px; /* Espace intérieur */
  border-radius: 10px; /* Coins arrondis */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Ombre pour donner du relief */
  margin: 20px auto; /* Marge pour centrer le box */
  max-width: 800px; /* Largeur maximale pour le box */
}


.group-box {
  border: 1px solid #ccc; /* Contour de la boîte */
  padding: 5px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.group-box-title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  display: block;
}



/* DeviceForm.css */
.form-container,
.horizButton {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 15px;
    padding-buttom: 15px;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 15px;
    margin-buttom: 15px;
    background-color:#FFF;
    border-radius: 8px;
}

.button-disabled {
  background-color: #ccc;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: not-allowed;
}

.button-disabled:hover {
  background-color: #ccc; /* Pas d'effet hover */
}
.file-preview {
     max-width: 100%;
}

.DDD {
    max-width: 100%;
}
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0px;
  box-sizing: border-box;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

#AAA {
  display: flex;
  flex-wrap: nowrap; /* Empêche le retour à la ligne des éléments */
  gap: 20px;
  flex-direction: column; /* Aligne les éléments horizontalement */
}

.column {
  flex: 1;
  min-width: 300px;
}

@media (min-width: 768px) {
  .abc {
          width: 100%; /* S'assure que chaque élément prend toute la largeur de l'écran */
  }

  thead {
    display: none;
  }

  .organ-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .organ-table tr {
    display: block;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }

  .organ-table th,
  .organ-table td {
    display: block;
    text-align: left;
    padding-left: 50%;
    width: 100%;
    box-sizing: border-box;
  }

  .organ-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    padding-left: 10px;
    font-weight: bold;
    color: #640097;
    white-space: nowrap;
  }

  .organ-table td {
    padding: 8px;
    border-bottom: none;
  }

  .organ-table tr:nth-child(even) {
    background-color: transparent;
  }

  .actions-cell {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: stretch;
  }

  .form-box {
    background-color: #FFFFFF; /* Couleur violet très clair */
  }


  .form-group {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .form-group label {
    width: 30%;
    text-align: right;
    margin-right: 10px;
  }

  .form-group input,
  .form-group select {
    width: 70%;
  }

  .form-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .column {
    width: 48%;
  }
}

@media (min-width: 1024px) {
  .form-container {
    justify-content: space-between;
  }

  .column {
    width: 48%;
  }
}

.horizButton {
  display: flex;
  align-items: flex-start; /* Aligne les éléments au début, peut être modifié selon les besoins */
}

#horiz1,
#horiz2,
{
    width: 25%;
}

#horiz3 {
    width: 50%;
}

#colB {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

#colC {
  flex: 1;
  width: 70%;
}
