/* Container principal pour la mise en page */

.date-filter {
    display: flex;
    flex-direction: column; /* Alignement vertical */
    align-items: center;    /* Centre les contenus horizontalement */
    justify-content: center; /* Centre verticalement */
    padding: 20px;
    border-radius: 10px;
    background-color: #f7f9fc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%; /* Assure que l'élément occupe toute la largeur disponible */
    max-width: 100%; /* Empêche tout dépassement horizontal */
    box-sizing: border-box; /* Inclut les bordures et les paddings dans la largeur */
    margin: auto;
    overflow-x: hidden; /* Masque tout débordement horizontal */

}

.date-field {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 80%;
  box-sizing: border-box;  /* Ensure the width includes padding and border */
  transition: border-color 0.3s ease;
  align-items: center;
}

.recherche {
    margin-top: 20px;
}

.date-field:focus {
  border-color: #007bff;  /* Change border color when focused */
  outline: none;
}



.main-container {
  display: flex;
  height: 100vh;
  align-items: center; /* Centre les éléments horizontalement */
}

h2 {
   text-align: center;
}

/* Styles pour la barre de progression */
.progress-container {
  margin: 20px 0;
}

.progress-bar {
  display: block;
  width: 100%;
  min-height: 3rem;
}

.progress-container {
    align-items: center; /* Centre les éléments horizontalement */
    padding-right: 5rem;
    padding-left: 5rem;
}

.progress-label {
  display: block;
  text-align: center;
  margin-top: 5px;
}

.progress-container {
  margin: 20px 0;
  display: flex;
  justify-content: center; /* Centre la barre de progression */
}

.progress-bar-background {
  width: 100%;
  max-width: 600px; /* Limite la largeur de la barre de progression */
  background-color: #ddd; /* Couleur de fond de la barre de progression */
  position: relative;
  border-radius: 5px; /* Coins arrondis */
  overflow: hidden; /* Cache les débordements */
  min-height: 2rem;
}

.progress-bar-fill {
  height: 20px; /* Hauteur de la barre de progression */
  background-color: #00F; /* Couleur de la barre de progression */
  text-align: center;
  line-height: 20px; /* Aligne le texte verticalement */
  color: white;
  border-radius: 5px 0 0 5px; /* Coins arrondis uniquement à gauche */
  position: relative;
  min-height: 2rem;
}

.progress-label {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%); /* Centre le texte verticalement */
}

.export-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.colExcel {
  display: flex;
  flex-wrap: nowrap; /* Empêche le retour à la ligne des éléments */
  gap: 20px;
  flex-direction: row; /* Aligne les éléments horizontalement */
}

.device-photo-planning {
   max-width: 300px;
   height: auto; /* Maintient le ratio d'aspect */
   display: block; /* Assure que l'image s'affiche correctement */
   margin: 0 auto; /* Centre l'image si besoin */
 }

 /* Styles spécifiques pour les petits écrans */
 @media (max-width: 768px) {
   .conteneur-principal {
     padding: 5px;
   }

   .conteneur-colonnes {
     flex-direction: column;
   }

   .carte-details {
     padding: 10px;
   }

   .colonne {
     width: 100%; /* S'assure que chaque élément prend toute la largeur de l'écran */
   }
   .abc {
        width: 100%; /* S'assure que chaque élément prend toute la largeur de l'écran */
      }

       .date-filter {
        flex-direction: column; /* Aligne les éléments verticalement sur mobile */
        padding: 10px;
    }

    .date-input-container {
        width: 80%; /* Évite les débordements */
    }

    .date-field {
        width: 80%; /* Prend toute la largeur disponible */
    }

 }



.date-field {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 80%;
  box-sizing: border-box;  /* Ensure the width includes padding and border */
  transition: border-color 0.3s ease;
}

.date-field:focus {
  border-color: #007bff;  /* Change border color when focused */
  outline: none;
}





