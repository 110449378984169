/* Conteneur principal du formulaire */
.form-box {
  background-color: #ccc; /* Couleur gris clair */
  padding: 20px; /* Espace intérieur */
  border-radius: 10px; /* Coins arrondis */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Ombre douce */
  margin: 20px auto; /* Marge automatique pour centrer */
  max-width: 800px; /* Largeur maximale */
}

/* Conteneur interne pour structurer les éléments */
.form-container2 {
  width: 80%;
  margin: 0 auto; /* Centrer horizontalement */
  background-color: #FFF; /* Couleur de fond blanc */
  border-radius: 8px; /* Coins arrondis */
  padding: 20px; /* Espacement interne */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Ombre légère */
}

/* Style des champs de saisie */
input[type="number"] {
  width: 100%; /* S'étend sur toute la largeur */
  padding: 10px; /* Espacement interne */
  margin: 10px 0; /* Marge verticale */
  border: 1px solid #ccc; /* Bordure grise */
  border-radius: 5px; /* Coins légèrement arrondis */
  box-sizing: border-box; /* Inclure la bordure dans les dimensions */
}

/* Style des boutons */
button {
  width: 100%; /* Même largeur que le champ */
  padding: 10px; /* Espacement interne */
  border: none; /* Pas de bordure */
  border-radius: 5px; /* Coins arrondis */
  color: white; /* Texte blanc */
  font-size: 14px; /* Taille de la police */
  cursor: pointer; /* Curseur pointeur */
  margin: 10px 0; /* Espacement vertical entre les boutons */
}

/* Effet de survol des boutons */
button:hover {
}

/* Tableau pour afficher les détails de l'article */
th, td {
  width: 33.33%;
  text-align: left; /* Alignement à gauche */
  padding: 10px; /* Espacement interne */
}

/* Titres et descriptions */
h3 {
  text-align: center; /* Centrer le texte */
  color: #333; /* Couleur du texte */
  margin-bottom: 20px; /* Espacement sous le titre */
}

p {
  font-size: 16px; /* Taille de la police */
  margin: 5px 0; /* Espacement vertical */
}
