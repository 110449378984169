.footer {
  color: #007bff;
  padding: 20px 0;
  text-align: center;
  position: relative;
  width: 100%;
  bottom: 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer p {
  margin: 0;
  padding-bottom: 10px;
  font-size: 0.9rem;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  display: inline;
  margin: 0 10px;
}

.footer-links a {
  color: white;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Responsive design */
@media (max-width: 768px) {
  .footer {
    padding: 15px 0;
  }

  .footer p {
    font-size: 0.8rem;
  }

  .footer-links li {
    display: block;
    margin: 5px 0;
  }
}
