/* DeviceList.css ou CGU.css */

/* Style général pour la page */
.cgu-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Titre principal */
.cgu-container h1 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
}

/* Sous-titres (les numéros des sections) */
.cgu-container h2 {
    font-size: 22px;
    margin-top: 20px;
    color: #444;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
}

/* Paragraphe principal */
.cgu-container p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
}

/* Liste numérotée */
.cgu-container ol {
    padding-left: 20px;
    margin-bottom: 20px;
}

/* Listes des éléments */
.cgu-container li {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.6;
}

/* Liens */
.cgu-container a {
    color: #007BFF;
    text-decoration: none;
}

.cgu-container a:hover {
    text-decoration: underline;
}

/* Mettez en gras les informations importantes */
.cgu-container strong {
    font-weight: bold;
    color: #333;
}
