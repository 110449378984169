th, td {
  width: 33.33%;
}

.form-group {
    width: 80%;
    align-items:center;
    justify-content: center;
    margin-right: 10%;
    margin-left: 10%;
    background-color: #FFF;
    padding-right: 10px;
    padding-left: 10px;
}

.btn-submit {
      width: 80%;
      align-items:center;
      justify-content: center;
      margin-right: 10%;
      margin-left: 10%;
      border-radius: 8px;
}
