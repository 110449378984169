.form-box {
  background-color: #ccc; /* Couleur violet très clair */
  padding: 20px; /* Espace intérieur */
  border-radius: 10px; /* Coins arrondis */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Ombre pour donner du relief */
  margin: 20px auto; /* Marge pour centrer le box */
  max-width: 800px; /* Largeur maximale pour le box */
}

th, td {
  width: 33.33%;
}

.form-container2 {
    width: 80%;
    align-items:center;
    justify-content: center;
    margin-right: 10%;
    margin-left: 10%;
    background-color: #FFF;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Assurez-vous que la modale est en avant-plan */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-buttons {
  margin-top: 20px;
}

.modal-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
}

.col1 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

#AAA {
  flex: 1;
  min-width: 300px;
}
