/* ArticleList.css */

/* ... (existing CSS rules) ... */

.recherche {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  max-width: 600px;
  margin: 20px auto;
}

.search-bar {
  padding: 10px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .search-bar {
    width: 100%;
  }

  .actions-cell {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Assurez-vous que la modale est en avant-plan */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-buttons {
  margin-top: 20px;
}

.modal-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
}
.col1 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

#AAA {
  flex: 1;
  min-width: 300px;
}