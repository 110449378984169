/* Conteneur pour les filtres de date */

.select {
   width: 100%;
   padding: 0px;
   box-sizing: border-box;
 }

.date-filters {
  display: flex;
  flex-direction: row; /* Aligne les champs côte à côte */
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f7f9fc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: auto;
  max-width: 600px;
}

/* Champ de date */
.date-field {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  transition: border-color 0.3s ease;
  width: 100%;
}

.date-field:focus {
  border-color: #007bff;
  outline: none;
}

/* Boutons d'exportation */
.export-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.colExcel {
  margin: 10px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



/* Table stylisée */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 2px 15px rgba(64, 64, 64, 0.1);
}

thead tr {
  background-color: #007bff;
  color: white;
  text-align: left;
}

th, td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
}

tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

tbody tr:nth-child(even) {
  background-color: #ffffff;
}

tbody tr:hover {
  background-color: #f5f5f5;
}

/* Réponse pour petits écrans */
@media (max-width: 768px) {
  .date-filters {
    flex-direction: column;
    gap: 10px;
    max-width: 100%;
  }

  .date-field {
    width: 100%;
  }

  table {
    font-size: 16px;
  }

  button {
    width: 100%;
  }

  .export-buttons {
    flex-direction: column;
  }
}
