#colB {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the buttons horizontally */
  align-items: center; /* Center the buttons vertically */
  margin: 0 auto;
}

#colC {
  flex: 1;
  min-width: auto; /* Adjust the minimum width for small screens */
  margin: 5px; /* Add margin to space the buttons */
}

.btn-submit {
      width: 80%;
      align-items:center;
      justify-content: center;
      margin-right: 10%;
      margin-left: 10%;
      border-radius: 8px;
}

/* Media query for small screens */
@media (max-width: 768px) {
  #colC {
    min-width: 200px; /* Reduce button width on small screens */
  }
  .actions-cell-email {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    width: 100%;
  }

}
