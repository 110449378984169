/* CheckListIntervention.css */
.form-container2 {
    width: 80%;
    align-items:center;
    justify-content: center;
    margin-right: 10%;
    margin-left: 10%;
    background-color: #FFF;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
}

.ContainrColumns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.col {
  flex: 1;
  min-width: 300px;
}

.device-photo {
  width: 300px; /* Largeur souhaitée */
  height: auto; /* Maintient le ratio de l'image */
  max-width: 100%; /* S'assure que l'image ne dépasse pas la taille de son conteneur */
  border-radius: 8px; /* Optionnel : arrondir les coins de l'image */
}



#BBB {
    min-height: 200px;
}
.checklist-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.titre_formulaire {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.content {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 200px); /* Ajustez en fonction de votre besoin */
}

.groupBox,
.form_a_droite {
  flex: 1;
  border: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  margin: 0; /* Assurez-vous qu'il n'y a pas de marge */
  max-width: 50%; /* Limitez la largeur maximale */
}

.groupBox {
  margin-right: 10px;
}

.form_a_droite {
  margin-left: 10px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="radio"] {
  margin-right: 5px; /* Espacement entre les boutons radio */
}

.radio-group {
  display: flex;
  gap: 10px; /* Espacement entre les boutons radio */
}

.radio-group label {
  display: flex;
  align-items: center;
  margin: 0;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.success-message {
  color: green;
  text-align: center;
  margin-top: 20px;
}

.colB {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-right: 10px;
  margin-left: 10px;
  justify-content: center; /* Center the buttons horizontally */
}

.colC {
  flex: 1;
  min-width: 300px;
}
