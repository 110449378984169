.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333;
  color: white;
}

.menu-toggle {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #1e1e1e, #383838);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
  transition: color 0.2s;
}

.close-menu:hover {
  color: #ff6b6b;
}

.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav button {
  background: none;
  border: none;
  margin: 10px 0;
  font-size: 20px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  padding: 10px 20px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.nav button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.nav button svg {
  margin-right: 12px;
  transition: transform 0.3s;
}

.nav button:hover svg {
  transform: translateX(5px);
}

.nav a {
  color: inherit;
  text-decoration: none;
}

.nav a:hover {
  text-decoration: none;
  color: #ff6b6b;
}

.menu-item {
  position: relative;
}

.menu-item button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  padding: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background 0.3s ease;
}

.menu-item button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* CSS du sous-menu amélioré */
.submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-width: 200px;
}

.menu-item:hover .submenu {
  display: block;
}

.submenu a {
  color: white;
  text-decoration: none;
  display: block;
  margin: 8px 0;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.submenu a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ff6b6b;
}

.account-menu {
  position: relative;
}

.account-toggle {
  background-color: #f9f9f9;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 1.5rem;
}

.account-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  min-width: 180px;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.account-menu:hover .account-dropdown,
.account-dropdown:hover {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.account-dropdown a,
.account-dropdown button {
  padding: 12px 15px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  color: #333;
  transition: background 0.3s ease;
}

.account-dropdown a:hover,
.account-dropdown button:hover {
  background-color: #f9f9f9;
}

.account-dropdown a:active,
.account-dropdown button:active {
  background-color: #eaeaea;
}

.account-dropdown a:first-child,
.account-dropdown button:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.account-dropdown a:last-child,
.account-dropdown button:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.account-dropdown a,
.account-dropdown button {
  display: flex;
  align-items: center;
}

.account-dropdown a .icon,
.account-dropdown button .icon {
  margin-right: 10px;
  font-size: 1.2rem;
}

.submenu {
  display: none;
  position: absolute;
  top: 100%; /* Le sous-menu sera juste en dessous du bouton */
  left: 50%; /* Centrer horizontalement */
  transform: translateX(-50%); /* Correction pour centrer précisément */
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-width: 200px;
}
