.plan-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  overflow: hidden;
  margin-top: 20px;
}

.plan-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.pin {
  position: absolute;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.pin-icon {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  position: relative;
}

.pin-icon::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 2px;
  height: 10px;
  background-color: red;
  transform: translateX(-50%);
}

.pin-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .pin-icon {
    width: 15px;
    height: 15px;
  }

  .pin-icon::after {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .pin-icon {
    width: 18px;
    height: 18px;
  }

  .pin-icon::after {
    width: 12px;
    height: 12px;
  }
}
