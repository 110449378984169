.form-box {
  background-color: #ccc; /* Couleur violet très clair */
  padding: 20px; /* Espace intérieur */
  border-radius: 10px; /* Coins arrondis */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Ombre pour donner du relief */
  margin: 20px auto; /* Marge pour centrer le box */
  max-width: 800px; /* Largeur maximale pour le box */
}

th, td {
  width: 33.33%;
}

.form-container2 {
    width: 80%;
    align-items:center;
    justify-content: center;
    margin-right: 10%;
    margin-left: 10%;
    background-color: #FFF;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
}

/* Ajoutez une règle pour les écrans plus petits */
@media (max-width: 600px) {
  .form-container2 {
    width: 90%; /* Augmente la largeur pour mieux s'adapter */
    margin: 0 auto; /* Centre la boîte */
    padding: 10px;
  }

  .form-group button {
    width: 100%; /* Ajuste la largeur des boutons à 100% pour les petits écrans */
    margin-top: 10px; /* Espace entre les boutons et les champs de formulaire */
  }
}

