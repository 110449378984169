/* DeviceList.css */

/* ... (other CSS rules) ... */

.photo-supplementaire {
    height: 150px;         /* Hauteur fixe */
    object-fit: cover;     /* Ajuste l'image pour couvrir toute la hauteur */
    width: auto;           /* Garde la largeur proportionnelle */
    margin: 5px;           /* Ajoute un espace entre les images */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  table-layout: fixed; /* Ensures columns take full width */
}

th, td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word; /* Ensures text wraps within cells */
  white-space: normal; /* Ensure text wraps within cells */
}

th {
  background-color: #640097;
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

td .button-container {
  display: flex;
  justify-content: center;
  gap: 5px;
}

button {
  margin-right: 5px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap;
}

button:hover {
  background-color: #0056b3;
  color: white;
}

.details-card {
  background-color: white;
  padding: 20px;
  margin: 20px 0;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.details-card h3 {
  margin-bottom: 10px;
  color: #007bff;
}

.details-card p {
  margin-bottom: 10px;
}

.details-card button {
  display: block;
  margin: 10px auto 0;
  background-color: #dc3545;
  color: white;
}

@media (max-width: 768px) {
  .abc {
          width: 100%; /* S'assure que chaque élément prend toute la largeur de l'écran */
  }
  thead {
    display: none; /* Hide table header on small screens */
  }

  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  tr {
    display: block;
    margin-bottom: 10px; /* Add spacing between rows */
    border-bottom: 1px solid #ddd; /* Add border to each row */
  }

  th, td {
    display: block;
    text-align: left;
    position: relative;
    padding-left: 50%; /* Add padding for label space */
    width: 100%;
    box-sizing: border-box;
    word-wrap: break-word; /* Ensures text wraps within cells */
    white-space: normal; /* Ensure text wraps within cells */
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    padding-left: 10px;
    font-weight: bold;
    color: #640097;
    white-space: nowrap;
    text-align: left;
  }

  td {
    padding: 8px;
    text-align: center; /* Center content for mobile */
    border-bottom: none;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }

  tr:nth-child(even) {
    background-color: transparent;
  }

  .actions-cell {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: stretch; /* Ensure buttons take full width */
  }
}

.recherche {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .abc {
          width: 100%; /* S'assure que chaque élément prend toute la largeur de l'écran */
  }
  .details-card {
    padding: 15px;
  }

  button {
    padding: 6px 10px;
    margin-right: 0; /* Remove right margin */
    width: 100%; /* Make buttons take full width */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width */
  }

  th, td {
    text-align: center;
    display: block;
    width: 100%;
    padding-left: 50%; /* Add padding for label space */
    word-wrap: break-word; /* Ensures text wraps within cells */
    white-space: normal; /* Ensure text wraps within cells */
  }

  tr {
    display: block;
    width: 100%;
    margin-bottom: 10px; /* Add spacing between rows */
    border-bottom: 1px solid #ddd; /* Add border to each row */
  }

  th::after,
  td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    padding-left: 10px;
    font-weight: bold;
    color: #640097;
    white-space: nowrap;
    text-align: center;
  }

  td {
    padding: 8px;
    text-align: center; /* Center content for mobile */
    border-bottom: none;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }

  tr:nth-child(even) {
    background-color: transparent;
  }

  .actions-cell {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: stretch; /* Ensure buttons take full width */
    width: 100%; /* Ensure actions cell takes full width */
  }

  table {
    width: 100%; /* Ensures table takes full width */
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Assurez-vous que la modale est en avant-plan */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-buttons {
  margin-top: 20px;
}

.modal-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
}
.col1 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

#AAA {
  flex: 1;
  min-width: 300px;
}

