body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f7f6;
}

.auth-page {
  display: flex;
  height: 100vh;
  width: 100%;
}

.right-side {
  background-color: #e0b3ff; /* Violet clair */
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right-side-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px; /* Distance between logo and the login form */
}

.logo-circle {
  width: 200px;
  height: 200px;
  background-color: #ffffff; /* White circle */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  margin-bottom: 20px;
}

.logo-circle img {
  width: 70%;
  height: auto;
}

.app-title {
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  margin: 0;
}

.right-side {
  background-color: #5B0A91;
  width: 50%;
  height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-side {
  background-color: #ffffff; /* Violet clair */
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}



.logo img {
  display: block;
  margin: 0 auto 20px;
  width: auto;
}

.auth-form label {
  display: block;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.auth-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f9f9f9;
}

.auth-form input:focus {
  border-color: #007bff;
  outline: none;
}

.auth-form button {
  width: 100%;
  padding: 12px;
  background-color: #5B0A91;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.auth-form button:hover:not(:disabled) {
  background-color: #0056b3;
}

.error-message {
  color: #ff0000;
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .auth-page {
    flex-direction: column;
  }

  .left-side, .right-side {
    width: 100%;
    height: 50vh;
  }

  .auth-form {
    padding: 20px;
  }

  .auth-form label {
    font-size: 20px;
  }

  .auth-form button {
    font-size: 16px;
  }


/* Masquer le panneau droit sur mobile */
  .right-side {
    display: none;
  }

  .auth-form {
    padding: 20px;
  }

  .auth-form label {
    font-size: 20px;
  }

  .auth-form button {
    font-size: 16px;
  }
}

@media (min-width: 769px) {
  /* Masquer le logo dans le formulaire d'authentification sur les grands écrans */
  .auth-form .logo {
    display: none;
  }
}
